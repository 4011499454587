import React from "react";
import { Radio } from "antd";
import "./index.css";
import { useNavigate, useLocation } from "react-router-dom";
import i18n from "../../language";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onChangeLg = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  const plainOptions = ["en", "uk"];

  return (
    <header className="HeaderContainer">
      <div className="logoContainer">
        <div className="title">{i18n.t("header.logoHeder")}</div>
        <div className="number">
          +38 (096) 480-55-51
          <div className="lang">
            <Radio.Group
              options={plainOptions}
              onChange={onChangeLg}
              optionType="button"
              buttonStyle="solid"
              value={i18n.language}
            />
          </div>
        </div>
      </div>
      <div className="navContainer">
        <div className="btnsContainer">
          <button
            onClick={() => navigate("/")}
            className={pathname === "/" ? "active" : ""}
            type="button"
          >
            {i18n.t("header.homePage")}
          </button>
          <button
            onClick={() => navigate("/gallery")}
            className={pathname === "/gallery" ? "active" : ""}
            type="button"
          >
            {i18n.t("header.gallery")}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
