import React, {useState} from "react";
import HomePage from "./components/HomePage";
import { withTranslation } from "react-i18next";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GalleryPage from './components/GalleryPage';


import "antd/dist/antd.css";
import "./styles/antd.css";
import Gallery from "./components/Gallery";

function App() {
  const [activeCard, setActiveCard] = useState();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gallery" element={<Gallery setActiveCard={setActiveCard} />} />
        <Route path="/gallery/:id" element={<GalleryPage activeCard={activeCard} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default withTranslation("common")(App);
