import React, {useEffect, useState, useMemo} from 'react';
import Footer from "../Footer";
import {Button, Spin} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Header from "../Header";
import i18n from "../../language";

const GalleryPage = () => {
    const navigate = useNavigate();
    const {id : idCard} = useParams();
    const [activeCard, setActiveCard]  = useState(undefined);

    useEffect(()=>{
        setActiveCard(dataGallery.find(el => +idCard === el.id));
        window.scrollTo(0, 0);
    },[i18n.language]);


    const dataGallery = useMemo(() => {
      return [
          {
            id: 1,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №1` ,
            iconPrimary: <img width='100%' src={require('../../images/gallery/one.jpg')} alt='one'/>,
            text: i18n.t('gallery.one')
          },
          {
            id: 2,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №2` ,
            iconPrimary: <img width='100%' src={require('../../images/gallery/two.jpg')} alt='two'/>,
            text: i18n.t('gallery.two')
          },
          {
            id: 3,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №2` ,
            iconPrimary: <img width='100%' src={require('../../images/gallery/twoWhite.jpg')} alt='twoWhite'/>,
            text: i18n.t('gallery.two')
          },
          {
            id: 4,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №3` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/three.jpg')} alt='three'/>,
            text: i18n.t('gallery.three')
          },
          {
            id: 5,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №4` ,
            iconPrimary: <img  width='100%'src={require('../../images/gallery/four.jpg')} alt='four'/>,
            text: i18n.t('gallery.four')
          },
          {
            id: 6,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №4  ${i18n.t('other.v')}` ,
            iconPrimary: <img  width='100%'src={require('../../images/gallery/fourWhite.jpg')} alt='fourWhite'/>,
            text: i18n.t('gallery.four')
          },
          {
            id: 22,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} 5` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/five.jpg')} alt=''/>,
            text: i18n.t('gallery.five')
          },
          {
            id: 7,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №5  ${i18n.t('other.v')}` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/fiveWhite.jpg')} alt='four'/>,
            text: i18n.t('gallery.five')
          },
          {
            id: 8,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №6` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/six.jpg')} alt='six'/>,
            text: i18n.t('gallery.six')
          },
          {
            id: 9,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №6  ${i18n.t('other.v')} ` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/sixWhite.jpg')} alt='six'/>,
            text: i18n.t('gallery.six')
          },
          {
            id: 10,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №8 ` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/eight.jpg')} alt='eight'/>,
            text: i18n.t('gallery.eight')
          },
          {
            id: 11,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №8  ${i18n.t('other.v')} ` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/eightWhite.jpg')} alt='eight'/>,
            text: i18n.t('gallery.eight')
          },
          {
            id: 12,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №12` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/twelve.jpg')} alt='twelve'/>,
            text: i18n.t('gallery.twelve')
          },
          {
            id: 13,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №12  ${i18n.t('other.v')} ` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/twelveWhite.jpg')} alt='twelveWhite'/>,
            text: i18n.t('gallery.twelve')
          },
          {
            id: 14,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №12XL` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/twelveSecondary.jpg')} alt='twelveSecondary'/>,
            text: i18n.t('gallery.twelve')
          },
          {
            id: 15,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №13` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/threrteen.jpg')} alt='threrteen'/>,
            text: i18n.t('gallery.thirteen')
          },
          {
            id: 16,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №13XL` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/threteenXl.jpg')} alt='threteenXl'/>,
            text: i18n.t('gallery.thirteenXl')
          },
          {
            id: 17,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №13/1` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/therteenWhite.jpg')} alt='therteenWhite'/>,
            text: i18n.t('gallery.thirteen')
          },
          {
            id: 18,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} ${i18n.t('other.altar')}` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/altar.jpg')} alt='altar'/>,
            text: i18n.t('gallery.altar')
          },
          {
            id: 19,
            title: `${i18n.t('header.logo')} ${i18n.t('other.altar')}` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/altarOpen.jpg')} alt='altar'/>,
            text: i18n.t('gallery.altar')
            },
          {
            id: 20,
            title: `${i18n.t('header.logo')} ${i18n.t('other.type')} ${i18n.t('other.sarcophagus')}` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/sarcofag.jpg')} alt='sarcofag'/>,
            text: i18n.t('gallery.sarcophagus')
          },
          {
            id: 21,
            title: `${i18n.t('header.logo')} ${i18n.t('other.sarcophagus')}` ,
            iconPrimary: <img  width='100%' src={require('../../images/gallery/sarkofagOpen.jpg')} alt='sarcofag'/>,
            text: i18n.t('gallery.sarcophagus')
            },
        ];
      }, [i18n.language]);

    return  <div>
    <Header />
    <Spin spinning={!activeCard}>
    <div  className="gallery">
        <Button className="btn" onClick={() => navigate('/gallery')}>{i18n.t('other.buck')}</Button> 
         <div className="cardPage">
             <div className="title">{activeCard?.title}</div>
             <div className="imgContainer"> 
             <div ocClick={() => setModalImg(el)} className="img">{activeCard?.iconPrimary}</div>
         </div>
           <div className="text"> {activeCard?.text}</div>
         </div>
       </div>    
      </Spin>
      <Footer />
    </div>;
};

export default GalleryPage;