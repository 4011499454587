import React from "react";
import i18n from "../../language";
import "./index.css";

const Footer = () => {
  return (
    <div className="footerContainer">
      <div>
        <div className="title">{i18n.t("footer.workingHours")}</div>
        <div className="days">
          {i18n.t("footer.days")} :{" "}
          <span className="time">{i18n.t("footer.time")}</span>
        </div>

        <div className="days">
          {i18n.t("footer.saturday")} : {""}
          <span className="time">{i18n.t("footer.timeSaturday")}</span>
        </div>

        <div className="days" style={{ marginTop: "10px" }}>
          {i18n.t("footer.dayOff")}
        </div>
      </div>
      <div className="contactContainer">
        <div className="title">{i18n.t("footer.contactTitle")}: </div>
        <div className="days">{i18n.t("footer.address")}</div>
        <div className="number">
          {i18n.t("footer.phoneNumber")} : +38 (096) 480-55-51
        </div>
        <div className="number">
          {i18n.t("footer.email")} : elitniygrob@gmail.com
        </div>
      </div>
    </div>
  );
};

export default Footer;
