import React, { useMemo } from "react";
import i18n from "../../language";
import {
  CheckOutlined,
  HomeOutlined,
  GlobalOutlined,
  LikeOutlined,
  GoldOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import "./index.css";

const Advantages = () => {
  const data = useMemo(() => {
    return [
      {
        title: i18n.t("advantages.firstTitle"),
        text: i18n.t("advantages.firstText"),
        icon: <CheckOutlined />,
      },
      {
        title: i18n.t("advantages.secondaryTitle"),
        text: i18n.t("advantages.secondaryText"),
        icon: <HomeOutlined />,
      },
      {
        title: i18n.t("advantages.thirdTitle"),
        text: i18n.t("advantages.thirdText"),
        icon: <GlobalOutlined />,
      },
      {
        title: i18n.t("advantages.fourthTitle"),
        text: i18n.t("advantages.fourthText"),
        icon: <LikeOutlined />,
      },
      {
        title: i18n.t("advantages.fifthTitle"),
        text: i18n.t("advantages.fifthText"),
        icon: <GoldOutlined />,
      },
      {
        title: i18n.t("advantages.sixthTitle"),
        text: i18n.t("advantages.sixthText"),
        icon: <TeamOutlined />,
      },
    ];
  }, [i18n.language]);

  return (
    <div className="advantages">
      <div className="title">{i18n.t("advantages.title")}</div>
      <div className="advantagesContainer">
        {data.map((data, index) => {
          return (
            <div key={index} className="card">
              <div className="icon">{data.icon}</div>
              <div className="info">
                <div className="title">{data.title}</div>
                <span className="test">{data.text}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Advantages;
