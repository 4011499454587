import React, {useEffect, useMemo} from "react";
import Footer from "../Footer";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import i18n from "../../language";
import '../Gallery/index.css';

const Gallery = () => {
  const navigate = useNavigate();

  useEffect( () => {
  const id = localStorage.getItem('activeсard');
  const num = +id * 330;
  const numOver = +id * 50;
  const screenWidth = window.screen.width;

   if(screenWidth <= 780) {
     scrollTo(0 , num);
   } else {
    scrollTo(0 , numOver);
   }
  },[]);

  const chageCard = (el) => {
    localStorage.setItem('activeсard', el.id);
    navigate(`/gallery/${el.id}`);
  };
 
  const dataGallery = useMemo(() => {
    return [
        {
          id: 1,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №1` ,
          iconPrimary: <img width='100%' src={require('../../images/gallery/one.jpg')} alt='one'/>,
          text: i18n.t('gallery.one')
        },
        {
          id: 2,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №2` ,
          iconPrimary: <img width='100%' src={require('../../images/gallery/two.jpg')} alt='two'/>,
          text: i18n.t('gallery.two')
        },
        {
          id: 3,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №2` ,
          iconPrimary: <img width='100%' src={require('../../images/gallery/twoWhite.jpg')} alt='twoWhite'/>,
          text: i18n.t('gallery.two')
        },
        {
          id: 4,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №3` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/three.jpg')} alt='three'/>,
          text: i18n.t('gallery.three')
        },
        {
          id: 5,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №4` ,
          iconPrimary: <img  width='100%'src={require('../../images/gallery/four.jpg')} alt='four'/>,
          text: i18n.t('gallery.four')
        },
        {
          id: 6,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №4  ${i18n.t('other.v')}` ,
          iconPrimary: <img  width='100%'src={require('../../images/gallery/fourWhite.jpg')} alt='fourWhite'/>,
          text: i18n.t('gallery.four')
        },
        {
          id: 22,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} 5` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/five.jpg')} alt=''/>,
          text: i18n.t('gallery.five')
        },
        {
          id: 7,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №5  ${i18n.t('other.v')}` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/fiveWhite.jpg')} alt='four'/>,
          text: i18n.t('gallery.five')
        },
        {
          id: 8,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №6` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/six.jpg')} alt='six'/>,
          text: i18n.t('gallery.six')
        },
        {
          id: 9,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №6  ${i18n.t('other.v')} ` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/sixWhite.jpg')} alt='six'/>,
          text: i18n.t('gallery.six')
        },
        {
          id: 10,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №8 ` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/eight.jpg')} alt='eight'/>,
          text: i18n.t('gallery.eight')
        },
        {
          id: 11,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №8  ${i18n.t('other.v')} ` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/eightWhite.jpg')} alt='eight'/>,
          text: i18n.t('gallery.eight')
        },
        {
          id: 12,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №12` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/twelve.jpg')} alt='twelve'/>,
          text: i18n.t('gallery.twelve')
        },
        {
          id: 13,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №12  ${i18n.t('other.v')} ` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/twelveWhite.jpg')} alt='twelveWhite'/>,
          text: i18n.t('gallery.twelve')
        },
        {
          id: 14,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №12XL` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/twelveSecondary.jpg')} alt='twelveSecondary'/>,
          text: i18n.t('gallery.twelve')
        },
        {
          id: 15,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №13` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/threrteen.jpg')} alt='threrteen'/>,
          text: i18n.t('gallery.thirteen')
        },
        {
          id: 16,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №13XL` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/threteenXl.jpg')} alt='threteenXl'/>,
          text: i18n.t('gallery.thirteenXl')
        },
        {
          id: 17,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} №13/1` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/therteenWhite.jpg')} alt='therteenWhite'/>,
          text: i18n.t('gallery.thirteen')
        },
        {
          id: 18,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} ${i18n.t('other.altar')}` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/altar.jpg')} alt='altar'/>,
          text: i18n.t('gallery.altar')
        },
        {
          id: 19,
          title: `${i18n.t('header.logo')} ${i18n.t('other.altar')}` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/altarOpen.jpg')} alt='altar'/>,
          text: i18n.t('gallery.altar')
          },
        {
          id: 20,
          title: `${i18n.t('header.logo')} ${i18n.t('other.type')} ${i18n.t('other.sarcophagus')}` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/sarcofag.jpg')} alt='sarcofag'/>,
          text: i18n.t('gallery.sarcophagus')
        },
        {
          id: 21,
          title: `${i18n.t('header.logo')} ${i18n.t('other.sarcophagus')}` ,
          iconPrimary: <img  width='100%' src={require('../../images/gallery/sarkofagOpen.jpg')} alt='sarcofag'/>,
          text: i18n.t('gallery.sarcophagus')
          },
      ];
    }, [i18n.language]);

  return (
    <div>
      <Header />
      <div className="gallery">
      <div className="title" >{i18n.t('gallery.title')}</div>
        <div
        className="container"
        >
          {dataGallery.map(el => <div key={el.id} onClick={() => chageCard(el)}  id={el.id} className="card">
            <span className="title">{el.title}</span>
            {el.iconPrimary}  
          </div>)}
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Gallery;
