/* eslint-disable no-undef */
import React from "react";
import i18n from "../../language";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import "./index.css";

const Banner = () => {
  const navigate = useNavigate();
  return (
    <div className="bannerContainer">
      <div className="infoContainer">
        <div className="photoContainer">
          <img width='450px' style={{borderRadius: '7px'}} src={require("../../images/gallery/fourWhite.jpg")} alt="banner" />
        </div>
        <div className="info">
          <div className="title">
            {i18n.t("banner.title")} `{i18n.t("header.logo")}`
          </div>
          <div className="secondaryTitle">{i18n.t("banner.text")}</div>
          <div className="secondaryTitle">{i18n.t("banner.textNext")}</div>
          <div className="secondaryTitle">{i18n.t("banner.production")}</div>
          <Button
            onClick={() => navigate("/gallery")}
            type="primary"
            className="btn"
          >
            {i18n.t("banner.btn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
