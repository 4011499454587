import React from "react";
import Banner from "../Banner";
import Advantages from "../Advantages";
import { Button } from "antd";
import Header from "../Header";
import Footer from "../Footer";
import i18n from "../../language";
import "./index.css";

const HomePage = () => {
  return (
    <div>
      <Header />
      <div className="homePage">
        <Banner />
        <Advantages />
        <div className="textInfoBox">
          <div className="textInfo">{i18n.t("other.textInfo")}</div>
        </div>
        <Button
          onClick={() => navigate("/gallery")}
          type="primary"
          className="btnFooter"
        >
          {i18n.t("banner.btn")}
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
