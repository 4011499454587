import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import localeUK from "./uk.json";
import localeEn from "./en.json";

i18n
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: "uk",
    fallbackLng: "uk",
    whitelist: ["uk", "en"],
    ns: ["common"],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
    },
    resources: {
      uk: { common: localeUK },
      en: { common: localeEn },
    },
  });

export default i18n;
